import styled from "styled-components"

import { ParadiseDevicePointMessageDialog } from "src/components/Paradise/ParadiseDevices/ParadiseDeviceDetails/ParadiseDevicePointMessages/ParadiseDevicePointMessageDialog"
import { getPointMessageType } from "src/data/devices/logic/deviceLogic"
import { IParadiseDevicePointMessage } from "src/data/paradise/paradiseDevices/types/paradiseDeviceQueryTypes"
import { TextButton } from "src/ui/Button/TextButton"
import { colorsLegacy } from "src/ui/colors"
import CodeIcon from "src/ui/icons/code.svg"
import { MBadge } from "src/ui/MBadge/MBadge"
import { spacing } from "src/ui/spacing"
import { formatDate } from "src/utils/l10n"

export function ParadiseDevicePointMessagesRow({
  message,
  deviceId,
  open,
  onClose,
}: {
  message: IParadiseDevicePointMessage
  deviceId: string
  open: boolean
  onClose: () => void
}) {
  return (
    <>
      <div>
        <div>
          <MBadge color="neutral" size="small">
            {getPointMessageType(message.message_type)}
          </MBadge>
        </div>
      </div>
      <div>
        {formatDate({
          date: new Date(message.timestamp_ms).toISOString(),
          clockType: "24",
          timezone: "utc",
        })}
      </div>
      <div>
        <ContentButton>
          <CodeIcon color="black" width={12} />
        </ContentButton>
      </div>
      <ParadiseDevicePointMessageDialog
        open={open}
        onClose={onClose}
        deviceId={deviceId}
        message={message}
      />
    </>
  )
}

const ContentButton = styled(TextButton)`
  background-color: ${colorsLegacy.backgroundGrayV2};
  padding: ${spacing.XS};
  border-radius: 5px;
  transition: all 0.2s;

  &:hover {
    background-color: ${colorsLegacy.backgroundGray};
  }
`

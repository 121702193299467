import { ParadiseSettingMultiValueFields } from "src/components/Paradise/ParadiseSettings/ParadiseSettingMultiValueFields"
import { PillsWrapper } from "src/components/Paradise/sharedStyles"
import { TSettingContainerOnSaveReturnType } from "src/components/Settings/Setting/SettingContainer"
import { usePutParadiseUpdateDevice } from "src/data/paradise/paradiseDevices/queries/paradiseDeviceQueries"
import { ICVar } from "src/data/paradise/paradiseDevices/types/paradiseDeviceQueryTypes"
import { MBadge } from "src/ui/MBadge/MBadge"

export function ParadiseDeviceCVars({
  currentCVars,
  deviceId,
  disabled,
}: {
  currentCVars: ICVar[]
  deviceId: string
  disabled: boolean
}) {
  const putUpdateDevice = usePutParadiseUpdateDevice()

  async function handleSave(v: string[]): TSettingContainerOnSaveReturnType {
    try {
      await putUpdateDevice.mutateAsync({
        deviceId,
        body: {
          cvars: v.map((cvar) => ({ key_value: cvar })),
        },
      })

      return {
        isSuccess: true,
      }
    } catch (error) {
      return {
        isSuccess: false,
      }
    }
  }

  return (
    <ParadiseSettingMultiValueFields
      title="CVars"
      displayValue={
        currentCVars.length > 0 ? (
          <PillsWrapper>
            {currentCVars.map((cvar) => (
              <MBadge key={cvar.key_value} size="small" color="neutral">
                {cvar.key_value}
              </MBadge>
            ))}
          </PillsWrapper>
        ) : (
          "-"
        )
      }
      initialTextFields={currentCVars.map((cvar) => cvar.key_value)}
      buttonLabel="Add CVar"
      onSave={handleSave}
      disabled={disabled}
    />
  )
}
